import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/layout"
import ArticleCard from "../components/articleCard"
import Pagination from "../components/pagination"
import Breadcrumb from "../components/breadcrumb"

const PostListTag = (props) => {
    const siteMetadata = props.data.site.siteMetadata
    const posts = props.data.allMarkdownRemark.edges
    const { currentPage, numPagesOnlyTag, tag } = props.pageContext
    const title = siteMetadata.labels.find(label => label.tag === tag).title
    const rootpath = "/" + tag + "/"
    return (
        <Layout title={title} adsense={true} path={props.location.pathname}>
            <div className="mt-3">
                <Breadcrumb title={title} />
            </div>
            <div className="">
                {posts.map((post) => (
                    <ArticleCard
                        key = {post.node.id}
                        slug = {post.node.fields.slug}
                        title = {post.node.frontmatter.title}
                        date = {post.node.frontmatter.date}
                        excerpt = {post.node.excerpt}
                        tags = {post.node.frontmatter.tags}
                        labels = {siteMetadata.labels}
                    />
                ))}
            </div>
            <div className="d-flex justify-content-center mt-4">
                <Pagination 
                    rootpath={rootpath}
                    currentPage={currentPage}
                    numPages={numPagesOnlyTag}
                />
            </div>
        </Layout>
    )
}

export const paginateTagQuery = graphql`
    query paginateTagQuery($skip: Int!, $limit: Int!, $tag: String!) {
        site {
            siteMetadata {
                labels {
                    tag
                    title
                    svg 
                    size 
                    color
                }
            }
        }
        allMarkdownRemark(
            limit: $limit
            skip: $skip
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { tags: { eq: $tag }, published: { eq: true } } }
        ) {
            totalCount
            edges {
                node {
                    excerpt(pruneLength: 140, truncate: true)
                    html
                    id
                    frontmatter {
                        title
                        date(formatString: "YYYY年M月D日")
                        tags
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`

export default PostListTag